import '../../styles/pages/fall-winter-2022/under-the-big-top.scss';
import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet"
// import ScrollAnimation from 'react-animate-on-scroll';
import MoreStories from "../../components/fall-winter-2022-stories/more-stories"
import ReactFullpage from '@fullpage/react-fullpage';
import { withPrefix } from 'gatsby'
import Icon from "../../components/icons"
import socialBanner from "../../images/fall-winter-2022/social-banners/under-the-big-top.jpg";
import { Preloader, Placeholder } from 'react-preloading-screen';
import { SwishSpinner } from "react-spinners-kit";



// import title from '../../images/fall-winter-2022/under-the-big-top/title-graphic.svg';
// import operatingTheStageConsole from '../../images/fall-winter-2022/under-the-big-top/operating-the-stage-console.svg';
// import producingTheSounds from '../../images/fall-winter-2022/under-the-big-top/producing-the-sounds.svg';
// import supervisingTheMakeupStudio from '../../images/fall-winter-2022/under-the-big-top/supervising-the-makeup-studio.svg';

const pluginWrapper = () => {
  require('../../scripts/fullpage.responsiveSlides.min.js');
  // require('../../scripts/fullpage.scrollHorizontally.min.js');
};

export default class UnderTheBigTop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      headerHide: false
    };
    // this.hideHeaderOnLoad = this.hideHeaderOnLoad.bind(this);
  }

  
  // componentDidMount() {
  //   this.hideHeaderOnLoad();
  // }

  // componentWillUnmount() {
  //   this.hideHeaderOnLoad();
  // }

  // hideHeaderOnLoad() {
  //   setTimeout(
  //     this.setState({headerHide: true}), 100000);
  // }

  render() {
    var pageTitle = 'Under the Big Top';
    var pageDesc = 'A sound engineer, automation technician and makeup artist are among the Cal State Fullerton alumni who have worked with Cirque du Soleil, the largest contemporary circus producer in the world.';
    var slug = 'under-the-big-top';

    return (
      <Layout headerStyle="white" locationInfo={this.props.location} headerHide={this.state.headerHide} issue="fall-winter-2022">
        <Helmet>
          <title>{pageTitle}</title>
          <body className={slug} />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>

        <Preloader fadeDuration={2000} style={{backgroundColor: '#111'}}>
        
        <ReactFullpage
          id="story-content"
          licenseKey={'74BC1699-BD29452E-B9391E28-1CBD2120'}
          // menu="#menu"
          pluginWrapper={pluginWrapper}
          normalScrollElements={'#mainNav, .text-container'}
          loopBottom={false}
          navigation={true}
          slidesNavigation={false}
          controlArrows={false}
          responsiveSlides={true}
          responsiveSlidesKey={'ZnVsbGVydG9uLmVkdV85MzFjbVZ6Y0c5dWMybDJaVk5zYVdSbGN3PT00Zkk='}
          onLeave ={(origin, destination, direction) => {

            if (direction === 'down') {
              this.setState({headerHide: true});
            } else {
              this.setState({headerHide: false});
            }

          }}
          afterLoad ={(origin, destination, direction) => {

            // console.log(destination.item.id);
            // var currentID = destination.item.id;
            // document.getElementById(currentID).getElementsByTagName('p').addClass('animated fadeIn');

            // if (currentID != 'intro') {
            // var el = document.getElementById(currentID),
            //   //modern browsers IE >= 10
            //   classList = 'classList' in el;
            //   for (var i = 0; i < el.children.length; i++) {
            //       var child = el.children[i];
            //       if (child.tagName == 'p') {
            //           if (classList) {
            //               child.classList.add('test');
            //           } else {
            //               child.className += ' test'
            //           }
            //       }
            //   }
            // }


          }}
          responsiveWidth={'769'}
          render={({ state, fullpageApi }) => {
            return (
              <ReactFullpage.Wrapper>
                <div className="section hero-section">
                  <div className="hero-text">
                    
                    <h1 className="animated bounceInDown delay-1s">
                      {/* <img src={title} alt={pageTitle} /> */}
                      <span className='title-top'>Under the</span>
                      <span className='title-bottom'>Big Top</span>
                    </h1>
                    <span className="sub-title animated bounceInUp delay-1s">{pageDesc}</span>
                    <span className="byline animated bounceInUp delay-1s">By Lynn Juliano<br />Photos courtesy of Cirque du Soleil, Anthony Murano, Kirsten Monson and Kathleen Price</span>

                    <button onClick={() => fullpageApi.moveSectionDown()} className="nav-button down animated bounceInUp delay-1s">
                      <Icon name="arrow-down" alt="Read Story" class="lg" />
                    </button>

                  </div>
                  <div className="tint" />
                  <video id="heroVideo" data-autoplay autoPlay="autoplay" loop muted playsInline>
                    <source src={withPrefix('/video/fall-winter-2022/cirque-hero-plate-2.webm')} type="video/webm; codecs=vp9,vorbis" />
                    <source src={withPrefix('/video/fall-winter-2022/cirque-hero-plate-2.mp4')} type="video/mp4" />
                    <track kind="captions" srcLang="en" label="English" src={withPrefix('/video/fall-winter-2022/cirque-hero-plate-2.vtt')} />
                  </video>
                </div>
                <div className="section" id="intro">
                    <div className='wrap small'>
                      <p><span className='intro-text'>High-flying acrobatics, reality-defying stunts, whimsical music, exquisite costumes and set designs</span> are all in a night’s work for the world-famous Cirque du Soleil.</p>
  
                      <p>Growing from a troupe of 20 street performers in the 1980s to the largest contemporary circus producer in the world, Cirque du Soleil boasted 44 active shows, nearly 5,000 employees and a total audience of more than 100 million people worldwide before the COVID-19 pandemic shuttered most of the live entertainment industry in March 2020.</p>
  
                      <p>Two Cal State Fullerton theatre arts alumni were among the Cirque du Soleil employees sidelined by the pandemic. Today, the sound and automation technicians are back behind the scenes of two new shows, “Drawn to Life” in Orlando, Florida, and “Mad Apple” in Las Vegas, Nevada</p>
  
                      <p>For them — along with another alumna and veteran Cirque du Soleil makeup artist who now teaches at CSUF — working for the company whose mission is to “invoke the imagination, provoke the senses and evoke the emotions of people around the world” remains a dream.</p>
                    </div>
                    <div className='tint' />
                </div>
                <div className="section" id="murano1">
                    <div className='text'>

                      <div className='slide-title'>
                        <h2>Producing the Sounds</h2>
  
                        <p className='title'>
                        Anthony Murano ’15 (B.A. theatre arts),<br />
                        sound technician for Cirque du Soleil
                        </p>
                      </div>

                      <p>“Cirque du Soleil’s shows have always amazed me with their high-level acrobatics, but more importantly the music and sound design of each of their shows,” says sound technician Anthony Murano. “The music is very whimsical and atmospheric, bringing many unique cultural instruments from around the world.”</p>

                      <p>Since joining Cirque du Soleil in 2017, Murano has worked on more than 1,000 performances with top shows “Luzia” and “Volta,” and was on the creation team of Cirque and The Walt Disney Co.’s new show, “Drawn to Life.” Transporting audiences into the inspiring world of Disney animation, the show premiered in November 2021 and was the first new production since the start of the pandemic.</p>

                      <p>As a sound technician, Murano’s responsibilities include deploying the sound system and patching microphones, amplifiers, speakers and interconnections.</p>

                      <p>“Every Cirque show uses a different sound system and different system components, which challenges us to understand a lot of different workflows and system architectures,” he shares.</p>

                      <p>The sound team of three to five technicians rotate mixing over 100 channels of audio for 2,600 audience members. Another technician is in the monitor booth mixing the live bands during the show. The team also manages the 34 wireless microphones and in-ear monitors backstage during the performances, and helps wire up the artists and band.</p> 


                    </div>
                    <div className="tint" />
                </div>
                <div className='section fp-auto-height-responsive' id="murano-video-slide">
                  <video id="murano-video" data-autoplay controls playsInline>
                    <source src={withPrefix('/video/fall-winter-2022/mixing-cirque-anthony-murano.mp4')} type="video/mp4" />
                    <track kind="captions" srcLang="en" label="English" src={withPrefix('/video/fall-winter-2022/mixing-cirque-anthony-murano.vtt')} />
                  </video>
                </div>
                <div className="section" id="murano2">
                    <div className='text left'>

                    <p>“We use a combination of wired mics in the band pits and wireless theatrical-style ear or hairline mics,” he explains. “Some microphones we hide inside the costumes so they are barely visible.”</p>

                    <p>When a big top tent show is touring, the group travels from city to city every six to 10 weeks, says Murano. “We need to make sure that the show and system is packed up correctly and efficiently loaded onto the truck. Then we get to the next city to unpack the truck and set everything up again. This process takes about two weeks.”</p>

                    <p>While the pandemic was devastating to those who work in the entertainment industry — Murano himself was out of work for 18 months — the “invaluable” education he received at Cal State Fullerton allowed him to bounce back quickly.</p>

                    <p>“The <a href="http://www.fullerton.edu/arts/theatre/" target="_blank" rel="noreferrer">Department of Theatre and Dance</a> has incredible professors who listen to your goals and push you toward them by creating challenges that mimic real-life situations,” he says. “The course structure gave me a solid foundation not only in my specialty of musical theater sound design, but also a well-rounded education of all the surrounding disciplines and how to interact with other departments on-site.”</p>

                    <p>When Murano is not working for Cirque du Soleil, he operates his own sound company, touring with such music artists as Kraftwerk and Willow.</p>
 


                    </div>
                    <div className="tint left" />
                </div>
                <div className="section" id="monson">
                    <div className='text'>

                      <div className='slide-title'>
                        <h2>Operating the Stage Console</h2>
  
                        <p className='title'>
                        Kirsten Monson ’15 (B.A. theatre arts),<br />
                        lead automation technician for Cirque du Soleil
                        </p>
                      </div>

                      <p>For Kirsten Monson’s 11th birthday, her dad bought the family tickets to see Cirque du Soleil’s “Varekai” at the OC Fair and Event Center. “I remember being absolutely blown away by the music, the lighting and the costumes,” she says.</p>

                      <p>From that point on, Monson was hooked. She made it a point to watch as many shows as she could — 17 to date — and remembers thinking, “I don’t know what I’m going to do, but I want to be a part of Cirque.”</p>

                      <p>She found her calling in the field of stage automation, which involves complex motion and synchronization of lighting to sound and scenery movements through such tools as electric motors, hydraulics, pneumatics, flying scenery and stage trucks.</p>

                      <p>Monson has worked on four Cirque shows: first as an intern for “Totem” on tour in Santa Monica, then as an automation technician for “KÀ” and “Michael Jackson One” in Las Vegas. </p>

                      <p>She currently is the lead automation technician for Cirque’s newest show in Las Vegas, “Mad Apple,” which opened at New York New York Hotel and Casino in May. Her responsibilities include programming and running the console during shows, collaborating with artists to integrate new acts into the show, and performing maintenance on winches and machinery. </p>

                    </div>
                    <div className="tint" />
                </div>
                <div className="section" id="monson2">
                    <div className='text'>

                      <p>Her career with Cirque du Soleil began with an internship while a student at Cal State Fullerton. “One of the key reasons that I’m in the position I am today is because I simply asked for the internship,” she says. “I asked for the job and I asked for the learning that I’ve received on the job.”</p>

                      <p>CSUF is one of two public programs in the state, along with San Diego State University, that trains theater students in stage automation and rigging — a technical skill sought by such employers as Disney, Universal Studios and Cirque du Soleil. Students in the program gain hands-on experience programming and building machines to create special effects such as a spinning stage or objects flying across the stage.</p>

                      <p>During her time as a student, Monson worked as a scenic lab assistant, helping to build each of the department shows and teaching stagecraft students.</p>

                      <p>“I pursued any automation opportunities I could within the technical department,” she explains. “I made an effort to show up, regardless if it was ‘my show’ or not. I just wanted to learn and get my hands on whatever I could, and it is a credit to my professors that they allowed me to do that.”</p>

                      <p>Though the pandemic nearly cost her the job she moved to a new state to pursue, Monson didn’t give up hope that one day she would be back behind the console for Cirque du Soleil.</p>

                    </div>
                    <div className="tint" />
                </div>
                <div className="section" id="price1">
                    <div className='text left'>

                      <div className='slide-title'>
                        <h2>Supervising the Makeup Studio</h2>
  
                        <p className='title'>
                        Kathleen Price ’97 (B.F.A. art-graphic design), former artistic makeup supervisor for Cirque du Soleil and current CSUF lecturer in theatre and dance
                        </p>
                      </div>

                      <p>Kathleen Price, Cal State Fullerton lecturer in theatre arts and former makeup artist for Cirque du Soleil, often tells students in her “Theatrical Makeup” class that working in entertainment means they are in the business of “what if” — meaning that situations are often unpredictable, but the show must go on.</p>

                      <p>Other tips she offers to students aspiring to work in the entertainment industry are: Make a solid plan and practice it a lot. Observe and learn from other talented professionals in the field. Be brave and say “yes” to new opportunities. Be someone who others look forward to being around. Keep learning and exploring. Hydrate and carry snacks.</p>

                      <p>These are lessons she learned in her decade of experience as artistic makeup supervisor for all the resident Cirque du Soleil shows in Las Vegas. In this role, Price served as the main point of contact for all makeup issues for “Mystère” at Treasure Island, “O” at Bellagio, “Zumanity” at New York New York, “KÀ” at MGM Grand, “The Beatles Love” at Mirage, “Criss Angel BeLIEve” at Luxor, and “Viva Elvis” and “Zarkana” at Aria.</p>

                      <p>“I especially enjoyed working with people from all over the world,” she says. “Any given Cirque show has people from 15-20 different countries. Getting to know so many people from different cultures and countries has given me a deep appreciation for the beauty of diversity and all that we each have to offer each other.”</p>

                    </div>
                </div>
                <div className="section" id="price2">
                    <div className='text'>

                      <p>Price says there were around 650 performers, 350 makeup designs across all eight shows, and 150 makeup designs for public relations and photoshoots.</p>

                      <p>“Performers are responsible for applying their makeup every night. When a new performer joined a show, I was responsible for teaching him or her the approved makeup design over several practice sessions,” says Price. “I also designed and implemented an annual makeup review process for every performer on each show, and taught one or two people in each show’s wardrobe shop the basics of the makeup designs and inventory management.”</p>

                      <p>While a student at Cal State Fullerton, Price worked as a “monster maker” for Knott’s Scary Farm before landing a “dream job” at Cirque du Soleil.</p>

                      <p>“At CSUF, I learned so many of the skills I still use today,” says Price. “Equally important are the relationships that I developed with both students and faculty. At the time, I had no idea this was the start of my professional network. That network has grown and is still alive and thriving today.  I am so grateful for all of my time at CSUF, now and then.”</p>


                    </div>
                </div>
                <div className='section final'>
                  <p>Support the College of the Arts<br /><a href="https://campaign.fullerton.edu/arts" target="_blank">campaign.fullerton.edu/arts</a></p>

                  <MoreStories slug={slug} />

                </div>
              </ReactFullpage.Wrapper>
            );
          }}
        />
        <Placeholder>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
            <SwishSpinner size={30} frontColor="#d1a32c" backColor="#ffc635" />
            <span style={{
              fontSize: '.7em',
              display: 'block', 
              marginTop: '30px', 
              color: '#ccc', 
              textTransform: 'uppercase',
              textAlign: 'center',
              letterSpacing: '5px'
            }}>
              Loading
            </span>
          </div>
				</Placeholder>
			</Preloader>
      </Layout>
    );
  }
} 